import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import { getAllTenants } from "../../axios/tenants";
import { getTenantBranches } from "../../axios/branch";
import { addUser, getAllUsers } from "../../axios/user";
import { ListContainer } from "../../components/listContainer";
import { Roles } from "../../utils/roles";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    marginLeft: "2%",
    marginRight: "2%",
  },
  containerTitle: {
    fontSize: "40px",
    lineHeight: "50px",
    textAlign: "left",
    height: "10px",
    marginTop: "25px",
  },
  containerSubtitle: {
    fontSize: "20px",
    lineHeight: "25px",
    textAlign: "left",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  addButton: {
    background: "#F56200",
    height: "50px",
    width: "212px",
    border: "2px solid #F56200",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "18px",
    lineHeight: "23px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
    marginTop: "46px",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "5px",
    marginTop: 15,
  },
  input: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #B8B8B8",
    borderRadius: "8px",
    height: 25,
    padding: "8px 16px",
    fontSize: "130%",
    marginBottom: 10,
  },
  button: {
    alignSelf: "end",
    justifySelf: "end",
    width: "40%",
  },
});

function Permissions() {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [emailCode, setEmailCode] = useState();
  const [role, setRole] = useState(Roles.ADMIN);
  const [tenantSelector, setTenantSelector] = useState();
  const [branchSelector, setBranchSelector] = useState();
  const [tenantsList, setTenantsList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [confirmUser, setConfirmUser] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const classes = useStyles();

  async function signUp() {
    if (!username || !password || !role) return;
    if ((role === Roles.TENANT || role === Roles.BRANCH) && !tenantSelector) return;
    if (role === Roles.BRANCH && !branchSelector) return;
    setLoadingSignUp(true);
    try {
      const res = await Auth.signUp({
        username,
        password,
      });
      const newUser = {
        _id: res.userSub,
        email: username,
        role: role,
      };
      if (role === Roles.TENANT || role === Roles.BRANCH) {
        newUser.tenantId = tenantSelector;
      }
      if (role === Roles.BRANCH) {
        newUser.branchId = branchSelector;
      }
      await addUser(newUser);
      setConfirmUser(true);
    } catch (error) {
      setShowErrorSnackbar(error.message);
      setLoadingSignUp(false);
      console.log("error signing up:", error);
    }
  }

  async function confirmSignUp() {
    setLoadingConfirm(true);
    try {
      await Auth.confirmSignUp(username, emailCode);
      setUserCreated(true);
      const userList = await getAllUsers();
      setUsers(userList.result);
    } catch (error) {
      setShowErrorSnackbar(error.message);
      setLoadingConfirm(false);
      console.log("error confirming sign up", error);
    }
  }

  useEffect(async () => {
    const tenantsData = await getAllTenants();
    setTenantsList(tenantsData.result);
    const userList = await getAllUsers();
    setUsers(userList.result);
  }, []);

  useEffect(async () => {
    const data = await getTenantBranches({
      tenantId: tenantSelector,
    });
    setBranchesList(data.result);
  }, [tenantSelector]);

  return (
    <div className={classes.container}>
      <Snackbar
        open={showErrorSnackbar}
        message={showErrorSnackbar}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setShowErrorSnackbar(false)}
      />
      <Drawer
        anchor="right"
        open={openEditor}
        onClose={() => {
          if ((loadingConfirm || loadingSignUp) && !userCreated) return;
          setOpenEditor(false);
          setUsername("");
          setPassword("");
          setEmailCode("");
          setRole("");
          setUserCreated(false);
          setConfirmUser(false);
          setTenantSelector();
          setBranchSelector();
          setLoadingConfirm(false);
          setLoadingSignUp(false);
        }}
        PaperProps={{
          style: {
            width: "40%",
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
          },
        }}
      >
        <h1>Create New User</h1>
        <div className={classes.form}>
          <label className={classes.label}>Username</label>
          <input
            className={classes.input}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label className={classes.label}>Password</label>
          <input
            className={classes.input}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label className={classes.label}>Role</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            style={{ height: "100%" }}
            className={classes.input}
          >
            {Object.entries(Roles).map(([key, value]) => (
              <option key={key} value={value}>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()} user</option>
            ))}
          </select>
          {role === "tenant" || role === "branch" ? (
            <>
              <label className={classes.label}>Tenant</label>
              <select
                value={tenantSelector}
                onChange={(e) => setTenantSelector(e.target.value)}
                style={{ height: "100%" }}
                className={classes.input}
              >
                {tenantsList.map((tenant) => {
                  return <option value={tenant.id}>{tenant.name}</option>;
                })}
              </select>
            </>
          ) : null}
          {tenantSelector && role == "branch" ? (
            <>
              <label className={classes.label}>Branch</label>
              <select
                value={branchSelector}
                onChange={(e) => setBranchSelector(e.target.value)}
                style={{ height: "100%" }}
                className={classes.input}
              >
                {branchesList.map((tenant) => {
                  return <option value={tenant.id}>{tenant.name}</option>;
                })}
              </select>
            </>
          ) : null}
          <Button
            className={classes.button}
            style={{ marginTop: 20 }}
            variant="contained"
            onClick={signUp}
            disabled={loadingSignUp}
          >
            Create
          </Button>
        </div>
        {confirmUser ? (
          <div className={classes.form}>
            <label className={classes.label}>Code</label>
            <input
              className={classes.input}
              placeholder="Code"
              value={emailCode}
              onChange={(e) => setEmailCode(e.target.value)}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={confirmSignUp}
              disabled={loadingConfirm}
            >
              Confirm
            </Button>
            {userCreated ? <div>User Created!</div> : null}
          </div>
        ) : null}
      </Drawer>
      <div className={classes.headerContainer}>
        <div>
          <h1 className={classes.containerTitle}>Users</h1>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => setOpenEditor(true)}
          >
            Add New user
          </Button>
        </div>
      </div>
      <ListContainer
        columns={[
          { name: "Email", key: "email" },
          { name: "Role", key: "role", center: true },
        ]}
        list={users}
      />
    </div>
  );
}

export default Permissions;
