import axios from "axios";
const URL = process.env.REACT_APP_ROOT_API;

export function getTenant(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/tenant`, { params: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllTenants() {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/tenants`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addTenant(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${URL}/tenant`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTenant(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(`${URL}/tenant`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
