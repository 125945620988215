export const Roles = {
    ADMIN: 'admin',
    TENANT: 'tenant',
    BRANCH: 'branch',
    KITCHEN: 'kitchen',
    CASHIER: 'cashier'
};

export const rolePermissions = {
    [Roles.ADMIN]: ['/products',  '/kitchen', '/cashier','/users'],
    [Roles.TENANT]: ['/branches/{id}', '/products'],
    [Roles.BRANCH]: ['/products/{id}'],
    [Roles.KITCHEN]: ['/kitchen'],
    [Roles.CASHIER]: ['/cashier']
};

export const loginRoutes = {
    [Roles.ADMIN]: "/products",
    [Roles.TENANT]: "/dashboards",
    [Roles.BRANCH]: "/dashboards",
    [Roles.KITCHEN]: "/kitchen",
    [Roles.CASHIER]: "/cashier"
};
