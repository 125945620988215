import React from "react";
import { makeStyles } from "@mui/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LeftMenu from "./components/leftMenu";
import Login from "./scenes/login";
import Dasbhoard from "./scenes/dashboards";
import Tenants from "./scenes/tenants";
import Branches from "./scenes/branches";
import Products from "./scenes/products";
import Permissions from "./scenes/permissions";
import Board from "./scenes/boards";

const theme = createTheme({
  palette: {
    primary: {
      light: "#F8914D",
      main: "#F56200",
      dark: "#C44E00",
    },
  },
});

const useStyles = makeStyles({
  viewContainer: {
    display: "flex",
    height: "100vh",
    flex: "auto",
    flexDirection: "row",
  },
  rightContainer: {
    display: "flex",
    flexGrow: 1,
    width: "90%",
    marginLeft: "298px",
  },
});

function App() {
  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className={classes.viewContainer}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/dashboards">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Dasbhoard />
              </div>
            </Route>
            <Route exact path="/tenants">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Tenants />
              </div>
            </Route>
            <Route path="/branches/:tenantId">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Branches />
              </div>
            </Route>
            <Route path="/branches">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Branches />
              </div>
            </Route>
            <Route path="/products/:branchId">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Products />
              </div>
            </Route>
            <Route exact path="/products">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Products />
              </div>
            </Route>
            <Route exact path="/users">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Permissions />
              </div>
            </Route>
            <Route exact path="/kitchen">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Board station={'Kitchen'} />
              </div>
            </Route>
            <Route exact path="/cashier">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <Board station={'Cashier'} />
              </div>
            </Route>
            <Route path="*">
              <LeftMenu />
              <div className={classes.rightContainer}>
                <NotFoundPage />
              </div>
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    </Router>
  );
}

function NotFoundPage() {
  return <h1 style={{ marginLeft: 20 }}>404 Not Found</h1>;
}
export default App;
