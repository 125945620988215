import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import logo from '../../assets/logodgo.png';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import BusinessIcon from '@mui/icons-material/Business';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LogoutIcon from '@mui/icons-material/Logout';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Loader from '../../components/loader';
import { rolePermissions } from '../../utils/roles';
import { useAuth } from '../../hooks/useAuth';

const useStyles = makeStyles({
  leftMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    top: 0,
    flexBasis: "20%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRight: "1px solid #B8B8B8",
    position: "fixed",
  },
  menuContainer: {
    padding: 20,
    width: "80%",
  },
  logoImage: {
    marginTop: 20,
  },
  itemContainer: {
    textTransform: "none",
    borderRadius: 40,
  },
  buttonContainer: {
    height: "100%",
    width: "80%",
    marginTop: "90%",
  },
  button: {
    border: "2px solid #F56200",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: "white",
    color: "#F56200",
    display: "flex",
    width: "100%",
    padding: "6px 16px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "1rem",
    cursor: "pointer",
    "&:hover": {
      color: "white",
      background: "#F56200",
    },
  },
  icon: {
    marginLeft: "8px",
  },
});

function LeftMenu() {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [userRole] = useState(localStorage.getItem('role'));

  const pathname = location.pathname;
  const classes = useStyles();

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) {
      history.push({ pathname: '/' });
    }
  }, []);

  const executeSignOut = async () => {
    setIsLoading(true);
    try {
      localStorage.clear();
      const result = await auth.signOut();
      if (result.success) {
        history.push({ pathname: '/' });
      } else {
        alert(result.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error signing out:', error);
      setIsLoading(false);
    }
  };


  function navItem(path, name, iconComp, index) {
    return (
      <MenuItem
        key={index}
        onClick={() => {
          history.push(path);
        }}
        selected={pathname.includes(path)}
        sx={{
          marginTop: 2,
          borderRadius: 1,
        }}
        color="primary"
      >
        <ListItemIcon>{iconComp}</ListItemIcon>
        <ListItemText>{name}</ListItemText>
      </MenuItem>
    );
  }

  function loadMenuItems() {
    let items = [];
    if (rolePermissions[userRole]) {
      rolePermissions[userRole].forEach((permission, index) => {
        const path = permission.replace('{id}', localStorage.getItem(`${userRole}Id`));
        let icon = <BusinessIcon fontSize="medium" />;
        if (permission.includes('tenants')) icon = <BusinessIcon fontSize="medium" />;
        if (permission.includes('branches')) icon = <StorefrontIcon fontSize="medium" />;
        if (permission.includes('products')) icon = <FastfoodIcon fontSize="medium" />;
        if (permission.includes('permissions')) icon = <LockPersonIcon fontSize="medium" />;
        if (permission.includes('kitchen')) icon = <NoteAltIcon fontSize="medium" />;
        if (permission.includes('cashier')) icon = <RoomServiceIcon fontSize="medium" />;
        const str = permission.split('/')[1];
        const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
        items.push(navItem(path, capitalizedStr, icon, index));
      });
    }
    return items;
  }


  return (
    <div className={classes.leftMenu}>
      <img src={logo} className={classes.logoImage} alt="logo" />
      <MenuList className={classes.menuContainer} sx={{
        color: "black",
        "&& .Mui-selected, && .Mui-selected:hover": {
          bgcolor: "#FBC099",
        },
        "& .MuiListItemButton-root:hover": {
          bgcolor: "#FBC099",
        },
      }}>
        {loadMenuItems()}
      </MenuList>
      <div className={classes.buttonContainer}>
        <button className={classes.button} onClick={() => executeSignOut()}>
          Log out
          <LogoutIcon className={classes.icon} fontSize="medium" />
        </button>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

export default LeftMenu;
