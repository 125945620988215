import axios from "axios";
const URL = process.env.REACT_APP_ROOT_API;

export function getAllProducts() {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/products`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getAllProductsPaginated(pageSize = 10, page = 1) {
  const results = [];
  let lastKey = null;
  let currentPage = 1;

  try {
    while (currentPage <= page) {
      const params = new URLSearchParams();
      params.append('limit', pageSize);
      if (lastKey) {
        params.append('lastKey', lastKey);
      }

      const response = await axios.get(`${URL}/products/paginated?${params.toString()}`);
      const data = response.data;

      if (currentPage === page) {
        results.push(...data.result);
      }

      lastKey = data.lastKey;

      if (!lastKey) {
        break;
      }

      currentPage++;
    }
    
    return results;

  } catch (error) {
    console.error('Error fetching paginated products:', error);
    throw error;
  }
}

export function getCountProducts() {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/products/count`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addProduct(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${URL}/product`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateProduct(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(`${URL}/product`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteProduct(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(`${URL}/deleteProduct`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBranchProducts(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/products`, { params: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProduct(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/product`, { params: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
