import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import SwapVertIcon from '@mui/icons-material/SwapVert';

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
  },
  pricesContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "5px",
  },
  priceContainer: {
    background: '#EFEFEF',
    width: '100%',
    borderRadius: '27px',
    padding: '5px',
    textAlign: 'center',
    margin: '0px 5px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0px 20px',
  },
  tr: {
    display: 'table-row',
    marginBottom: '10px',
  },
  td: {
    
    padding: 10,
    textAlign: 'start',
    borderBottom: '1px solid #B8B8B8',
    borderTop: '1px solid #B8B8B8',
  },
  tdFirst: {
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
    borderLeft: '1px solid #B8B8B8',
    borderBottom: '1px solid #B8B8B8',
    borderTop: '1px solid #B8B8B8',

  },
  tdLast: {
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    borderRight: '1px solid #B8B8B8',
    borderBottom: '1px solid #B8B8B8',
    borderTop: '1px solid #B8B8B8',
    background: 'linear-gradient(#B8B8B8,#B8B8B8) left  no-repeat',
    backgroundSize: "1px 50%",
  },
  tdFirstHead: {
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',

  },
  tdLastHead: {
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 1,
      left: 3,
      height: '70%',
      backgroundColor: '#BEBEBE',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  th: {
    paddingLeft: 20,
    padding: 10,
    background: "#EFEFEF",
    textAlign: 'start',
    fontWeight: 'normal',
  },
  thCentered: {
    textAlign: 'center',
  },
  thContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  thContentPrice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionsWrapper: {
      marginLeft: '27%',
  },
  boldText: {
    fontWeight: 'bold',
  },
});

export const ListContainer = (props) => {
  let { searchTerm, list, columns, newProduct, setNewProduct } = props;
  const [nameSort, setNameSort] = useState(true);
  const [categorySort, setCategorySort] = useState(false);
  const [nameSortAsc, setNameSortAsc] = useState(true);
  const [categorySortAsc, setCategorySortAsc] = useState(true);
  

  
  const hasRequiredColumns = columns.some(col => col.name === 'Name') &&
                             columns.some(col => col.name === 'Category');
  
  
  let filteredList = list;

  if (hasRequiredColumns) {
    if (newProduct) {
      const newest = filteredList.reduce((latest, item) => {
        return !latest || new Date(item.createdAt) > new Date(latest.createdAt) ? item : latest;
      }, null);
      filteredList = filteredList.filter(item => item.id !== newest?.id);
      filteredList = [newest, ...sortAndFilter(filteredList)];
      setNewProduct(false);
    } else {
      filteredList = sortAndFilter(filteredList);
    }
  }
                           
  function sortAndFilter(listParam) {
     const tmpList = listParam.slice().sort((a, b) => {
       if (nameSort) {
         if (a.name && b.name) {
           return nameSortAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
         }
       } else if (categorySort) {
         if (a.category && b.category) {
           return categorySortAsc ? a.category.localeCompare(b.category) : b.category.localeCompare(a.category);
         }
       }
       return 0;
     });
                           
     return tmpList.filter(item =>
       item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       item.category.toLowerCase().includes(searchTerm.toLowerCase())
     );
  }

  const sortName = () => {
    setNameSort(true);
    setCategorySort(false);
    setNameSortAsc(!nameSortAsc);
  };

  const sortCategory = () => {
    setCategorySort(true);
    setNameSort(false);
    setCategorySortAsc(!categorySortAsc);
  };

  const classes = useStyles();

  function getItem(col, ele) {
    if (ele==null ) return;
    if (col.actions) {
      return col.actions.map((ac, index) => (
        <div
          key={index}
          style={{ display: "inline", marginRight: 20, cursor: "pointer" }}
          onClick={() => ac.click(ele)}
        >
          {ac.icon}
        </div>
      ));
    }
    if (col.isSwitch) {
      return <Switch checked={ele[col.key]} />;
    }
    if (col.length) {
      if (ele[col.key] && ele[col.key].length) {
        return ele[col.key].length;
      }
      return 0;
    }
    if (col.key === "price") {
      return (
        <div>
          <div>Small: ${ele[col.key].small}</div>
          <div>Medium: ${ele[col.key].medium}</div>
          <div>Large: ${ele[col.key].large}</div>
        </div>
      );
    }
    if (col.key === "stock") {
      return `${ele[col.key]} units`;
    }
    return ele[col.key];
  }

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr>
            {columns &&
              columns.map((col) => (
                <th
                  key={col.name}
                  className={`${col.name === "Actions" ? classes.tdLastHead : ''}
                              ${classes.th}
                              ${col.name === "Name" ? classes.tdFirstHead : ''} 
                            `}
                >
                  <div className= {col.key === "price" ? classes.thContentPrice : classes.thContent}
                  >
                    {col.name === 'Name' && <SwapVertIcon fontSize="medium" onClick={sortName} />}
                    {col.name === 'Category' && <SwapVertIcon fontSize="medium" onClick={sortCategory} />}

                    {col.name && 
                      <div 
                      className= {col.name === "Actions" ? classes.actionsWrapper : ''}
                      >
                        {col.name}
                      </div>
                    }
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {filteredList &&
            filteredList.map((ele, rowIndex) => (
              <tr key={rowIndex} className={classes.tr}>
                {columns &&
                  columns.map((col, colIndex) => (
                    <td
                      key={col.name}
                      className={`${classes.td} ${
                        colIndex === 0 ? classes.tdFirst : ''} ${
                        colIndex === columns.length - 1 ? classes.tdLast : ''}`}
                    >
                      <div 
                        className={`${ col?.actions || col.key=='category' ? classes.cell : '' }
                                    ${col.name === 'Name' ? classes.boldText : ''}`}
                      >
                        {getItem(col, ele)}
                      </div>
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListContainer;
