export const capitalizeFirstLetter = (str) => {
    if (!str) return "No status";
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatPrice = (number) => {
    const formattedNumber = parseFloat(number).toFixed(2);

    return `$${formattedNumber}`;
};

export const getColorForElapsedTime = (creationDate) => {
    const elapsedTime = Math.floor((Date.now() - new Date(creationDate)) / 1000);

    let color = '#868686';
    if (elapsedTime >= 180 && elapsedTime < 300) {
        color = '#F1A614';
    } else if (elapsedTime >= 300) {
        color = '#CD1332';
    }

    return color;
};

