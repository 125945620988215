import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth } from "../../hooks/useAuth";
import Loader from "../../components/loader";
import logo from "../../assets/logo-white.png";
import { loginRoutes } from "../../utils/roles";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  imgContainer: {
    width: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#F56200",
    backgroundImage: "url('./Login.png')",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  form: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  welcome: {
    marginTop: 0,
    fontWeight: 700,
    fontSize: "40px",
  },
  label: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "5px",
    marginTop: 15,
  },
  input: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #B8B8B8",
    borderRadius: "8px",
    height: 25,
    padding: "8px 16px",
    fontSize: "130%",
    marginBottom: 10,
  },
  button: {
    display: "flex",
    backgroundColor: "#F56200",
    width: "100%",
    padding: "12px 24px",
    fontSize: "18px",
    fontWeight: 600,
    color: "white",
    marginTop: "30px",
    border: "2px solid #F56200",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    marginLeft: "8px",
  },
});

function Login() {
  const auth = useAuth();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    if (userRole && loginRoutes[userRole]) {
      history.push({ pathname: loginRoutes[userRole] });
    }
  }, []);

  const executeSignIn = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await auth.signIn(username, password);
    if (result.success) {
      const userRole = localStorage.getItem("role");
      history.push({ pathname: loginRoutes[userRole] || "/dashboards" });
    } else {
      setShowErrorSnackbar(result.message);
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Snackbar
        open={showErrorSnackbar}
        message={showErrorSnackbar}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setShowErrorSnackbar(false)}
      />
      <div className={classes.imgContainer}>
        <img src={logo} alt="Drivingo" />
      </div>
      <div className={classes.formContainer}>
        <h2 className={classes.welcome}>Welcome back</h2>
        <form className={classes.form} noValidate onSubmit={executeSignIn}>
          <label className={classes.label}>Email</label>
          <input
            className={classes.input}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label className={classes.label}>Password</label>
          <input
            className={classes.input}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className={classes.button} type="submit" disabled={isLoading}>
            Log in
            <LoginIcon className={classes.icon} fontSize="medium" />
          </button>
        </form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

export default Login;
