import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import GraphContainer from "../../components/graphContainer";
import { pieData, barData, tableData } from "./dummyData";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 10,
  },
  topContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  topLeftContainer: {
    flex: 3,
  },
  topRightContainer: {
    marginLeft: 30,
    flex: 2,
  },
  bottomContainer: {
    marginTop: 30,
    width: "100%",
  },
  tableFormat: {
    fontSize: "120%",
    marginBottom: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

function Dasbhoard() {
  const classes = useStyles();
  const [userRole] = useState(localStorage.getItem("role"));

  function formatCurrency(number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(number);
  }

  return (
    <div className={classes.container}>
      <h1 style={{ alignSelf: "start" }}>Glad to see you again {userRole}</h1>
      <div className={classes.topContainer}>
        <div className={classes.topLeftContainer}>
          <GraphContainer title="Top Tenants" subtitle="Last Month">
            <div style={{ width: "100%", paddingRight: 40, paddingLeft: 40 }}>
              {tableData &&
                tableData.map((ele, ix) => {
                  return (
                    <div className={classes.tableFormat}>
                      <div>
                        <div style={{ display: "inline" }}>{ix + 1}.</div>
                        <div style={{ marginLeft: 15, display: "inline" }}>
                          {ele.name}
                        </div>
                      </div>
                      <div> {formatCurrency(ele.total)}</div>
                    </div>
                  );
                })}
            </div>
          </GraphContainer>
        </div>
        <div className={classes.topRightContainer}>
          <GraphContainer title="Income">
            <Pie
              style={{ maxWidth: "200px", maxHeight: "200px" }}
              data={pieData}
            />
          </GraphContainer>
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <GraphContainer title="Top Tenants" subtitle="Last Month">
          <Bar
            options={{
              indexAxis: "y",
            }}
            data={barData}
            style={{ maxHeight: "300px", maxWidth: "100%" }}
          />
        </GraphContainer>
      </div>
    </div>
  );
}

export default Dasbhoard;
