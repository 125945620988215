import React, { useState, useEffect, useCallback, useRef } from 'react';
import { STATION_TYPE } from '../../utils/enums';

const TimerComponent = ({ order, className, setColorForOrder, station }) => {
    const padZero = (num) => {
        return num < 10 ? `0${num}` : num.toString();
    };

    const calculateElapsedTime = useCallback((creationDate) => {
        const elapsedTime = Math.floor((Date.now() - new Date(creationDate)) / 1000);
        const minutes = Math.floor(elapsedTime / 60);
        const seconds = elapsedTime % 60;
        const formattedTime = `${padZero(minutes)}:${padZero(seconds)}`;
        return { formattedTime, elapsedTime };
    }, []);

    const [elapsedTimeInfo, setElapsedTimeInfo] = useState(calculateElapsedTime(order.creationDate));
    const intervalRef = useRef();

    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            const { formattedTime, elapsedTime } = calculateElapsedTime(order.creationDate);
        
            setElapsedTimeInfo({ formattedTime, elapsedTime });

            let color = '#868686';
            if (elapsedTime >= 60 && elapsedTime < 120) {
                color = '#F1A614';
            } else if (elapsedTime >= 120) {
                color = '#CD1332';
            }
            setColorForOrder(order.id, color);
        }, 1000);
    };

    useEffect(() => {
        startInterval();
        return () => clearInterval(intervalRef.current);
    }, []);

    return (
        station === STATION_TYPE.KITCHEN && (
            <div className={className}>
                <h2>{elapsedTimeInfo.formattedTime}</h2>
            </div>
        )
    );
};

export default TimerComponent;
