import axios from "axios";
const URL = process.env.REACT_APP_ROOT_API;

export function getPreparedPendingOrders() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${URL}/orders`);
      const sortedOrders = sortOrdersByOrderNumber(response.data.result)
      resolve(sortedOrders);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPaymentPendingOrders() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${URL}/pendingPaymentOrders`);
      const sortedOrders = sortOrdersByOrderNumber(response.data.result)
      resolve(sortedOrders);
    } catch (error) {
      reject(error);
    }
  });
}


export function updateOrder(orderId, updatedData) {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(`${URL}/order?id=${orderId}`, updatedData)
      .then((response) => {
        resolve(response.data.message); 
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function sortOrdersByOrderNumber(orders) {
  return orders.sort((a, b) => a.orderNumber - b.orderNumber);
}