import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getBranch } from "../../axios/branch";
import Popup from '../../components/popUp/index';

import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  addProduct,
  getCountProducts,
  getAllProductsPaginated,
  getBranchProducts,
  updateProduct,
  deleteProduct,
} from "../../axios/product";
import { ListContainer } from "../../components/listContainer";
import ProductEditor from "./productEditor";
import { height, width } from "@mui/system";

const useStyles = makeStyles( (theme) => ({
  searchInput: {
    width: '90%',
    height: '70px',
    borderRadius: "12px",
    color: "#FFFFFF",
    fontSize: "5px",
    margin: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      borderRadius: "12px",
      backgroundColor: '#EFEFEF',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiInputAdornment-root': {
      color: 'black',
    },
    '& .MuiInputBase-root': {
      width: '96%',
      height: '50%',
    },
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    marginLeft: "2%",
    marginRight: "2%",
  },
  containerTitle: {
    fontSize: "40px",
    lineHeight: "50px",
    textAlign: "left",
    height: "10px",
    marginBottom: theme.spacing(2),
  },
  containerSubtitle: {
    fontSize: "20px",
    lineHeight: "25px",
    textAlign: "left",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    flexDirection: "row",
    width: '100%', 
    gap: theme.spacing(0), 
  },

  addButton: {
    background: "#F56200",
    height: "50px",
    width: "170px",
    border: "2px solid #F56200",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "18px",
    lineHeight: "23px",
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '6vh',
    marginRight: '5px',
  },
  navigationButton: {
    background: "#F56200",
    width: "0%",
    border: "2px solid #F56200",
    borderRadius: "8px",
    color: "#FFFFFF",
    height: '100%',
  },
  disabledButton: {
    background: "gray",
    border: "2px solid #F56200",
    borderRadius: "8px",
    color: "#gray",
  },
  boxPagesCount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    gap: theme.spacing(1),
    height: '50%', 
  },
  boxDropdown: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    height: '50%',
    width: 70, 
    maxWidth: '100%', 
    minWidth: 40,
  },
  pageInput: {
    width: "10%",
    height: '100%', 
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  headerContainer: {
    gap: theme.spacing(1), 
    display: "flex",
    flexDirection: "column",
    alignItems: "normal",
    width: '100%',
  },
}));

function Products() {
  const [products, setProducts] = useState([]);
  const [branchName, setBranchName] = useState();
  const [tenantName, setTenantName] = useState();
  const [openEditor, setOpenEditor] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); 
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedText, setSelectedText] = useState('10 Rows');
  const [newProduct, setNewProduct] = useState(false);
  const params = useParams("/products/:tenantId");
  const { branchId } = params;
  const classes = useStyles();

  useEffect(() => {
    const fetchTotalItems = async () => {
      try {
        const res = await getCountProducts();
        setTotalItems(res.totalItems);
        setTotalPages(Math.ceil(res.totalItems / itemsPerPage));
      } catch (error) {
        console.error('Error fetching total items:', error);
      }
    };
    fetchTotalItems();
  }, []);

  useEffect( () => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage));
  }, [itemsPerPage]);
  
  useEffect( ()=> {
    fetchProducts(itemsPerPage,currentPage)
  }, [itemsPerPage,currentPage])

  useEffect(async () => {
    setTenantName("ALL");
    setBranchName();
    fetchProducts();
  }, [branchId]);

  const fetchProducts = async (pageSize,page) => {
    try {
      const data = await getAllProductsPaginated(pageSize,page);
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);      
    }
  }
  
  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage));
  }, [itemsPerPage]);
  

  const handleNext = () => {
    const nextPage = currentPage + 1;
    if( nextPage<=totalPages ){
      setCurrentPage(nextPage);
    }
  };

  const handlePrev = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setCurrentPage(prevPage);
    }
  };

  const handlePageJump = (event) => {
    const number = Number(event.target.value);
    if (isNaN(number) || number < 1 || number > totalPages) {
      setCurrentPage('');
    } else {
      setCurrentPage(number);
    }
  };

  const handleNumberOfItems = (event) => {
    const value = event.target.value;
    let text = '';

    switch (value) {
      case 10:
        text = '10 Rows';
        break;
      case 15:
        text = '15 Rows';
        break;
      case 25:
        text = '25 Rows';
        break;
      case 50:
        text = '50 Rows';
        break;
      case 100:
        text = '100 Rows';
        break;
      default:
        text = '';
        break;
    }

    setItemsPerPage(value);
    setSelectedText(text);
  };
  const handleOpen = () => {
    setOpenDropdown(true);
  };

  const handleClose = () => {
    setOpenDropdown(false);
  };

  

  const handleDelete = async () => {
    deleteProduct(selected).then(()=>{
      fetchProducts(itemsPerPage,currentPage);
      setIsPopupOpen(false);
    })
  }

  async function saveProduct(product) {
    if (selected) {
      await updateProduct(product);
    } else {
      await addProduct(product);
      setNewProduct(true);
    }
    setSelected();
    setOpenEditor(false);
    const data = await getBranchProducts({
      branchId: branchId,
    });
    setProducts(data.result);
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };


  return (
    <div className={classes.container}>
      <Popup
        isOpen={isPopupOpen}
        title='Delete Product'
        label={`You are about to delete the product ${selected?.name}`}
        onCancel={() => setIsPopupOpen(false)}
        cancelLabel='Cancel'
        onAccept={() => handleDelete()}
        acceptLabel='Delete'
      />
      <ProductEditor
        open={openEditor}
        closeAction={() => setOpenEditor(false)}
        selected={selected}
        saveAction={saveProduct}
      />
      <div className={classes.headerContainer}>
        <div style={{ width: '35%' }}>
          <h1 className={classes.containerTitle}>Products</h1>
        </div>


        <div className={classes.buttonContainer} >
          <TextField
              sx = {{ display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                margin: '10px 0',
                width: '40%',
                height: '70px',
              }}
              placeholder="Search by name or category" 
              value={searchTerm} 
              onChange={handleSearch} 
              className={classes.searchInput} 
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
          />
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => {
              setSelected();
              setOpenEditor(true);
            }}
          >
            Add Product
          </Button>
        </div>

      </div>
      
      <ListContainer
        columns={[
          { name: "Name", key: "name" },
          { name: "Price", key: "price", },
          { name: "Category", key: "category" },
          { name: "Stock", key: "stock" },
          {
            actions: [
              {
                icon: <ModeEditIcon />,
                click: (ele) => {
                  setSelected(ele);
                  setOpenEditor(true);
                },
              },
              {
                icon: <DeleteIcon />,
                click: (ele) => {
                  setSelected(ele);
                  setIsPopupOpen(true);
                },
              },
            ],
            center: true,
            name: "Actions"
          },
        ]}
        list={products}
        searchTerm={searchTerm}
        newProduct={newProduct}
        setNewProduct={setNewProduct}
      />
      <Box className={classes.paginationContainer}>
        
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
            padding: '8px',
            borderRadius: '4px',
          }}
        >   <Box >
              Rows per page:
            </Box>

            <Select
              id="simple-select"
              value={itemsPerPage}
              onChange={handleNumberOfItems}
              onOpen={handleOpen}
              onClose={handleClose}
              open={openDropdown}
              IconComponent={openDropdown ? ExpandLessIcon : ExpandMoreIcon}
              renderValue={() => {
                return openDropdown ? selectedText : `${itemsPerPage}`
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: '150px', 
                    overflowY: 'auto', 
                  },
                },
              }}
            >
              <MenuItem value={5}>5 Rows</MenuItem>
              <MenuItem value={10}>10 Rows</MenuItem>
              <MenuItem value={15}>15 Rows</MenuItem>
              <MenuItem value={25}>25 Rows</MenuItem>
              <MenuItem value={50}>50 Rows</MenuItem>
              <MenuItem value={100}>100 Rows</MenuItem>

            </Select>
        </Box>
        
        <Box className={classes.boxPagesCount}>

          <Button
              variant="outlined"
              className={classes.navigationButton}
              onClick={handlePrev}
          >
            <NavigateBeforeIcon />
          </Button>

          <TextField
              className={classes.pageInput}
              id="outlined-basic"
              variant="outlined"
              value={currentPage}
              onChange={handlePageJump}
          />

           <Box> of {totalPages}</Box>

          <Button
            variant="outlined"
            className={classes.navigationButton}
            onClick={handleNext}
          >
            <NavigateNextIcon />
          </Button>

        </Box>
        
      </Box>
      
    </div>
  );
}

export default Products;
