export const ORDER_STATUS = {
    IN_PREPARATION: 'in preparation',
    DELIVERED: 'delivered',
    CANCELLED: 'cancelled',
    READY: 'ready'
  };


export const PAYMENT_STATUS = {
  PENDING: 'pending',
  PAID: 'paid'
}