import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { getPreparedPendingOrders, getPaymentPendingOrders, updateOrder } from '../../axios/order';
import { ORDER_STATUS, PAYMENT_STATUS } from '../../utils/orders';
import { STATION_TYPE } from '../../utils/enums';
import { capitalizeFirstLetter, formatPrice, getColorForElapsedTime } from '../../utils/helperFunctions';
import TimerComponent from './TimerComponent';
import Check from '../../assets/boards/check.svg';
import OrderDetailsModal from './OrderDetailsModal';

const useStyles = makeStyles({
    kitchenBoard: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'scroll',
        height: '80%',
    },
    commonStyles: {
        border: 'solid 2px',
        padding: '1%',
        borderRadius: '4px',
        fontWeight: 'bolder',
    },
    order: {
        flex: '0 0 25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        padding: '0 10px 10px 10px',
        border: '1px solid #ccc',
        borderRadius: '15px',
        maxWidth: '20%',
        minHeight:'223px',
        maxHeight: '48%',
        marginBottom: '10px',
        marginLeft: '2%',
        marginRight: '2%',
    },
    orderBreakDown: {
        backgroundColor: 'white',
        height: '80%',
        overflowY: 'auto',
        borderRadius: '0 0 10px 10px',
    },
    containerTitle: {
        fontSize: '40px',
        textAlign: 'left',
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        marginLeft: '2%',
        marginRight: '2%',
    },
    containerSubtitle: {
        fontSize: '24px',
        fontWeight: 500,
        textAlign: 'left',
    },
    orderNumber: {
        backgroundColor: '#F56200',
        borderRadius: '0 0 10px 10px',
        color: '#fff',
        width: '20%',
        height: '90%',
        minHeight:'40px'
    },
    orderHeader: {
        display: 'flex',
        flexDirection: 'row',
        height: '15%',
    },
    orderHeaderText: {
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginLeft: '18%',
    },
    categoryHeader: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#D9D9D9',
        fontSize: '12px',
        color: '#868686',
        fontWeight: 'bold',
    },
    cashierOrderHeader: {
        borderRadius: '10px',
        backgroundColor: '#D9D9D9',
        fontSize: '10px',
        color: '#868686',
        height: '40%',
        width: '35%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight:'bold'
    },
    cashierOrderHeaderCheck: {
        borderRadius: '10px',
        backgroundColor: '#9BDDAD',
        fontSize: '10px',
        color: '#008A25',
        height: '40%',
        width: '35%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight:'bold'
    },
});

const Board = (props) => {
    const [orders, setOrders] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const { station } = props;
    const classes = useStyles();
    let clickTimer = null;
    let previousClickTime = 0;
    const FETCH_INTERVAL_MS = 25000;

    useEffect(() => {
        const fetchPendingOrders = async () => {
            try {
                const result = station === STATION_TYPE.KITCHEN ? await getPreparedPendingOrders() : await getPaymentPendingOrders();
                setOrders(result);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchPendingOrders();

        const interval = setInterval(fetchPendingOrders, FETCH_INTERVAL_MS);

        return () => clearInterval(interval);
    }, [station]);

    const updateOrderStatus = (orders, orderId, productIndex, category, status) => {
        return orders.map(order => {
            if (order.id === orderId) {
                const updatedOrderList = Object.keys(order.orderList).reduce((acc, currCategory) => {
                    const updatedProducts = order.orderList[currCategory].Products.map((product, index) => {
                        if (currCategory === category && index === productIndex) {
                            return { ...product, status };
                        }
                        return product;
                    });
                    acc[currCategory] = { ...order.orderList[currCategory], Products: updatedProducts };
                    return acc;
                }, {});

                const allProductsReady = Object.values(updatedOrderList).every(categoryData => {
                    return categoryData.Products.every(product => product.status === ORDER_STATUS.READY);
                });

                return {
                    ...order,
                    orderList: updatedOrderList,
                    status: allProductsReady ? ORDER_STATUS.READY : order.status
                };
            }
            return order;
        });
    };

    const setOrderForUpdate = async (orderId, updateType) => {
        const orderToUpdate = orders.find(order => order.id === orderId);
    
        if (!orderToUpdate) {
            throw new Error('Order not found');
        }
    
        let updatedOrder;
        if (updateType === 'payment') {
            updatedOrder = {
                ...orderToUpdate,
                paymentStatus: PAYMENT_STATUS.PAID,
            };
        } else if (updateType === 'delivery') {
            updatedOrder = {
                ...orderToUpdate,
                isDelivered: true,
            };
        }
    
        try {
            await updateOrder(orderId, updatedOrder);
        } catch (error) {
            console.error(`Error updating ${updateType} status:`, error);
        }
    };

    const handleOrderUpdate = (orderId) => {
        setOrderForUpdate(orderId, 'delivery');
        setOrders(prevOrders =>
            prevOrders.filter(order => order.id !== orderId)
        );
        setIsModalOpen(false);
    };


    const filterOrdersWithAllProductsReady = (orders) => {
        return orders.filter(order => order.status !== ORDER_STATUS.READY);
    };

    const handleProductClick = async (order, productIndex, product) => {
        if (station !== STATION_TYPE.KITCHEN) {
            return;
        }
        const { category } = product;

        const updatedOrders = updateOrderStatus(orders, order.id, productIndex, category, ORDER_STATUS.READY);

        try {
            const orderToUpdate = updatedOrders.find(o => o.id === order.id);
            await updateOrder(order.id, orderToUpdate);
        } catch (error) {
            console.error('Error updating order:', error);
        }
        const filteredOrders = filterOrdersWithAllProductsReady(updatedOrders);
        setOrders(filteredOrders);
    };

    const handleDoubleTapKitchen = async (orderId) => {
        const updatedOrders = orders.map(order => {
            if (order.id === orderId) {
                const updatedOrderList = Object.keys(order.orderList).reduce((acc, category) => {
                    const updatedProducts = order.orderList[category].Products.map(product => {
                        return { ...product, status: ORDER_STATUS.READY };
                    });
                    acc[category] = { ...order.orderList[category], Products: updatedProducts };
                    return acc;
                }, {});

                return { ...order, orderList: updatedOrderList, status: ORDER_STATUS.READY };
            }
            return order;
        });

        try {
            const orderToUpdate = updatedOrders.find(o => o.id === orderId);
            await updateOrder(orderId, orderToUpdate);
        } catch (error) {
            console.error('Error updating order:', error);
        }
        const filteredOrders = filterOrdersWithAllProductsReady(updatedOrders);
        setOrders(filteredOrders);
    };

    const handleClick = (orderId) => {
        const currentTime = Date.now();
        const timeDifference = currentTime - previousClickTime;

        if (timeDifference <= 300) {
            if (station === STATION_TYPE.KITCHEN) {
                handleDoubleTapKitchen(orderId);
            } else {
                const clickedOrder = orders.find(order => order.id === orderId);
                setSelectedOrder(clickedOrder);
                setIsModalOpen(true);
            }
        } else {
            clearTimeout(clickTimer);
            clickTimer = setTimeout(() => {
                clearTimeout(clickTimer);
            }, 300);
        }
        previousClickTime = currentTime;
    };

    const handleDoubleClick = (orderId) => {
        if (station === STATION_TYPE.KITCHEN) {
            handleDoubleTapKitchen(orderId);
        } else {
            const clickedOrder = orders.find(order => order.id === orderId);
            setSelectedOrder(clickedOrder);
            setIsModalOpen(true);
        }
    }

    const setColorForOrder = (orderId, color) => {
        setOrders(prevOrders =>
            prevOrders.map(order =>
                order.id === orderId ? { ...order, color } : order
            )
        );
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const getOrderContainerClass = (orderId) => {
        return station === STATION_TYPE.KITCHEN ? classes.order : classes.order;
    };

    const shouldRenderCheck = (product) => {
        return product.status === ORDER_STATUS.READY;
    };

    const shouldRenderPrice = (orderId) => {
        return orderId === selectedOrder?.id;
    };
    const getColorBackground = (order) => {
        
        if(STATION_TYPE.KITCHEN === station){
            return getColorForElapsedTime(order.creationDate);
        } 
        return order.status === ORDER_STATUS.READY ?'#008A25':'#868686';  
    };

    return (
        <div className={classes.container}>
            <div>
                <h1 className={classes.containerTitle}>{station} Board</h1>
            </div>
            <div style={{ alignSelf: 'center' }} className={classes.kitchenBoard}>
                {orders.map((order, orderIndex) => (
                    <div
                        key={order.id}
                        style={{ backgroundColor:order.status === ORDER_STATUS.READY ?'#008A25' :getColorBackground(order) }}
                        className={getOrderContainerClass(order.id)}
                        //onClick={() => handleClick(order.id)}
                        onDoubleClick={() => handleDoubleClick(order.id)}
                    >
                        <div className={classes.orderHeader}>
                            {station === STATION_TYPE.KITCHEN ? (
                                <div className={classes.orderNumber}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                    <span style={{fontSize: '15px', color: '#933B00', alignSelf: 'flex-start', paddingLeft:'5px' }}>#</span>
                                    <div style={{ fontSize: '16px', fontWeight: '500' }}> {String(order.orderNumber % 1000).padStart(3, '0')}</div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        color: '#fff',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        minHeight: '40px',
                                    }}
                                >
                                    {order.status !== ORDER_STATUS.READY 
                                        ?<div className={classes.cashierOrderHeader}>{capitalizeFirstLetter('in progress')}</div>
                                        : <div className={classes.cashierOrderHeaderCheck}>{capitalizeFirstLetter(order.status)}</div>}

                                        <div style={{ fontSize: '20px', fontWeight: 'bolder' }}>#{String(order.orderNumber % 1000).padStart(3, '0')}</div>
                                    {order.paymentStatus !== PAYMENT_STATUS.PAID 
                                        ?<div className={classes.cashierOrderHeader}>{capitalizeFirstLetter(order.paymentStatus)}</div>
                                        : <div className={classes.cashierOrderHeaderCheck}>{capitalizeFirstLetter(order.paymentStatus)}</div>}
                                    
                                </div>
                            )}
                            <TimerComponent
                                key={order.id}
                                order={order}
                                className={classes.orderHeaderText}
                                setColorForOrder={setColorForOrder}
                                station={station}
                            />
                        </div>
                        <div
                            style={{paddingTop:'6px', paddingTop:'10px' ,color: '#fff', fontSize: '10px', display: 'flex', justifyContent: 'center', marginBottom: '3%', fontWeight:'bold' }}
                        >
                            Double tap to {station === STATION_TYPE.KITCHEN ? 'complete' : 'open order'}
                        </div>
                        <div className={classes.orderBreakDown}>
                            {Object.entries(order.orderList).map(([category, categoryData]) => (
                                <div key={`${category}-${orderIndex}`}>
                                    <div className={classes.categoryHeader}>-{category}-</div>
                                    {categoryData.Products.map((product, productIndex) => (
                                        <div
                                            key={`${productIndex}-${category}-${order.id}`}
                                            onClick={() => handleProductClick(order, productIndex, product)}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    padding: '4% 0 4% 6%',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                style={!shouldRenderCheck(product) ? {
                                                    border: 'solid 2px #F56200',
                                                    padding: '1%',
                                                    borderRadius: '4px',
                                                    color: '#F56200',
                                                    fontWeight: 'bolder',
                                                    alignSelf: 'start',
                                                } : {
                                                    border: 'solid 2px #008A25',
                                                    padding: '1%',
                                                    borderRadius: '4px',
                                                    color: '#FFFFFF',
                                                    backgroundColor: '#008A25',
                                                    fontWeight: 'bolder',
                                                    alignSelf: 'start',
                                                }}
                                                >
                                                {product.count}
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'start',
                                                        marginLeft: '5%',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <div style={{ fontWeight: 'bolder', color: shouldRenderCheck(product) ? '#008A25' : 'inherit' }}>{capitalizeFirstLetter(product.size)} {product.name}</div>
                                                    <div style={{ alignSelf: 'baseline', color: '#00000099' }}>
                                                        {product.customs && product.customs.map((addon, index) => {
                                                            return (
                                                                <div key={index}>
                                                                {addon.name} <br />
                                                                </div>
                                                            );
                                                            })
                                                        }
                                                        {product.extras && product.extras.map((addon, index) => {
                                                            return (
                                                                <div key={index}>
                                                                {addon.name} <br />
                                                                </div>
                                                            );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', width: '30%', justifyContent: 'center' }}>
                                                    {shouldRenderCheck(product) && <img src={Check} alt="Check" />}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <OrderDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} order={selectedOrder} setOrderForUpdate={setOrderForUpdate} handleOrderUpdate={handleOrderUpdate} />
        </div>
    );
};

export default Board;
