import axios from "axios";
const URL = process.env.REACT_APP_ROOT_API;

export function getAllBranches() {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/branches`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addBranch(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${URL}/branch`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateBranch(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(`${URL}/branch`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTenantBranches(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/branches`, { params: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBranch(body) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/branch`, { params: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
