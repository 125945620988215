import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { ReactComponent as UploadIcon } from './../../assets/upload.svg';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles({
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "5px",
    marginTop: 15,
  },
  input: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #B8B8B8",
    borderRadius: "8px",
    height: 25,
    padding: "8px 16px",
    fontSize: "18px",
    marginBottom: 10,
  },
  inputButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #B8B8B8",
    borderRadius: "8px 0 0 8px",
    height: 25,
    padding: "8px 16px",
    fontSize: "18px"
  },
  imageContainer: {
    borderRadius: "8px",
    border: '2px dashed #B8B8B8',
    padding: '10px',
    width: '95%',
    height: '35%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#FCFCFC',
    marginTop: '6px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block'
  },
  imageUploadContainer: {
    marginTop: '6px',
    backgroundColor: '#FCFCFC',
    borderRadius: "8px",
    border: '2px dashed #B8B8B8',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '100%',
    height: '350px',
    boxSizing: 'border-box',
    marginBottom: '10px',
    position: 'relative',
  },
  hiddenFileInput: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  uploadIcon: {
    marginBottom: '10px',
  },
});
function ProductEditor(props) {
  const { open, closeAction, selected, saveAction } = props;
  const [loadingEditor, setLoadingEditor] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const [name, setName] = useState("");
  const [priceSmall, setPriceSmall] = useState("");
  const [priceMedium, setPriceMedium] = useState("");
  const [priceLarge, setPriceLarge] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [stock, setStock] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  
  const [tags, setTags] = useState({});
  const [customs, setCustoms] = useState({});
  const [extras, setExtras] = useState({});
  const [isDataMissing, setIsDataMissing] = useState(false);
  
  const [isOnePriceSet, setIsOnePriceSet] = useState(false);

useEffect(() => {
    setIsOnePriceSet( (!!priceSmall) || (!!priceMedium) || (!!priceLarge) );
}, [priceSmall, priceMedium, priceLarge]);

  const classes = useStyles();
  const fileInputRef = React.useRef(null);

  const [isEditing, setIsEditing] = useState([false,false,false]);
  const [tag, setTag] = useState("");
  const [custom, setCustom] = useState("");
  const [extra, setExtra] = useState("");

  const initializeStates = () => {
    setName("");
    setPriceSmall("");
    setPriceMedium("");
    setPriceLarge("");
    setCategoryId("");
    setStock("");
    setDescription("");
    setImage(null);
    setTags({});
    setCustoms({});
    setExtras({});
  };

  const handleButtonClick = (index) => {
    const newEditing = [isEditing[0], isEditing[1], isEditing[2]];
    newEditing[index] = !isEditing[index];
    setIsEditing(newEditing);
  };

  useEffect(() => {
    if (selected && selected.imageUrl) {
      setImageSrc(selected.imageUrl);
    } else {
      setImageSrc("");
    }
  }, [selected]);

  useEffect(() => {
    setLoadingEditor(false);
    setIsEditing([0,0,0]);
    setCustom("");
    setTag("");
    setExtra("");
    if (selected) {
      setName(selected.name);
      setPriceSmall(selected.price.small || "");
      setPriceMedium(selected.price.medium || "");
      setPriceLarge(selected.price.large || "");
      setCategoryId(selected.categoryId);
      setDescription(selected.description);
      setStock(selected.stock);
      if(selected.tags)
        setTags(selected.tags);
      else
        setTags({});
      if(selected.customs)
        setCustoms(selected.customs);
      else
        setCustoms({});
      if(selected.extras)
        setExtras(selected.extras);
      else 
        setExtras({});
      if (selected.imageUrl) {
        setImageSrc(selected.imageUrl);
      } else {
        setImageSrc("");
      }
    } else {
      initializeStates();
      setImageSrc("");
    }
  }, [selected, open]);

  const validate = () => {
    if (!name || !isOnePriceSet || !categoryId) {
      setIsDataMissing(true);
      setLoadingEditor(false);
      return;
    }
    setIsDataMissing(false);
    const productData = {
      name,
      price: {
        small: parseFloat(priceSmall),
        medium: parseFloat(priceMedium),
        large: parseFloat(priceLarge),
      },
      categoryId,
      description,
      tags,
      stock,
      image,
      customs,
      extras
    };

    if (selected && selected.id) {
      productData.id = selected.id;
    }

    if (!stock || stock == "") {
      productData.stock = 0;
    }

    saveAction(productData);
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const image = await blobToBase64(file);
      setImage(image);
      setImageSrc(image);
    }
  };

  const handlePriceSmallChange = (e) => {
    let value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setPriceSmall(value);
    }
  };

  const handlePriceMediumChange = (e) => {
    let value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setPriceMedium(value);
    }
  };

  const handlePriceLargeChange = (e) => {
    let value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setPriceLarge(value);
    }
  };

  const handleStockChange = (e) => {
    let value = e.target.value;

    if (value === '' || value === '0' || /^[1-9]\d{0,9}$/.test(value)) {
      setStock(value);
    }
  };


  const countSelectedTags = (tags) => {
    return Object.values(tags).filter(Boolean).length;
  };

  const handleTagChange = (tag) => {
    const selectedCount = countSelectedTags(tags);

    if (tags[tag] || selectedCount < 3) {
      setTags({ ...tags, [tag]: !tags[tag] });
    }
  };

  const handleTagDelete = (tag) => {
    const newTags = { ...tags };
    delete newTags[tag];
    setTags(newTags);
  };
  const addTag = (tag) => {
    if(tag === "") return;
    if(tags.hasOwnProperty(tag)) return;
    const newTags = { ...tags };
    newTags[tag] = false;
    setTags(newTags);
    setTag("");
  };

  const handleCustomChange = (custom) => {
    if (customs) {
      setCustoms({ ...customs, [custom]: !customs[custom] });
    }
  };

  const handleCustomDelete = (custom) => {
    const newCustoms = { ...customs };
    delete newCustoms[custom];
    setCustoms(newCustoms);
  };
  const addCustom = (custom) => {
    if(custom === "") return;
    if(customs.hasOwnProperty(custom)) return;
    const newCustoms = { ...customs };
    newCustoms[custom] = false;
    setCustoms(newCustoms);
    setCustom("");
  };

  const handleExtrasChange = (extra) => {
    if (extras) {
      setExtras({ ...extras, [extra]: { ...extras[extra], isSelected: !extras[extra].isSelected } });
    }
  };
  
  const handleExtrasPriceChange = (extra, price) => {
    if (extras && /^\d*\.?\d{0,2}$/.test(price)) {
      setExtras({ ...extras, [extra]: { ...extras[extra], price: price } });
    }
  };
  
  const addExtras = (extra) => {
    if(extra === "" || extras.hasOwnProperty(extra)) return;
    const newExtras = { ...extras };
    newExtras[extra] = { isSelected: false, price: '' };
    setExtras(newExtras);
    setExtra("");
    console.log(extras,newExtras);
  };
  
  const handleExtrasDelete = (extra) => {
    setExtras(prevExtras => {
      const newExtras = {...prevExtras};
      delete newExtras[extra];
      return newExtras;
    });
  };
 
  const resetFields = () => {
    initializeStates();
    setIsDataMissing(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        if (loadingEditor) return;
        closeAction();
        resetFields();
        setLoadingEditor(false);
      }}
      PaperProps={{
        style: {
          width: "40%",
          paddingBottom: 0,
          paddingLeft: 40,
          paddingRight: 40,
        },
      }}
    >
      <h1 style={{ color: "#F56200" }}>{selected ? "Edit Product" : "New Product"}</h1>
      <div className={classes.form}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginRight: 10,
              maxWidth: '45%'
            }}
          >
            <label style={{ color: "#F56200" }} className={classes.label}>Name <span>*</span> </label>
            <input
              className={classes.input}
              style={{ borderColor: isDataMissing && !name ? "red" : "B0B0B0" }}
              placeholder="Name of the product"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginLeft: 10,
              maxWidth: '45%',
            }}
          >
            <label style={{ color: "#F56200" }} className={classes.label}>Category <span>*</span> </label>
            <select
              className={classes.input}
              style={{ borderColor: isDataMissing && !categoryId ? "red" : "#B0B0B0", minHeight: "43px" }}
              value={categoryId} 
              onChange={(e) => setCategoryId(e.target.value)}
            >
              <option value="">Select a category</option>
              <option value="1">Burgers</option>
              <option value="2">Desserts</option>
              <option value="3">Drinks</option>
              <option value="4">Fries</option>
              <option value="5">Fruits</option>
              <option value="6">Meals</option>
            </select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: '45%',
            }}
          >
            <label style={{ color: "#F56200" }} className={classes.label}>Price  <span>*</span> </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: 10,
                  maxWidth: '30%',
                }}
              >
                <input
                  className={classes.input}
                  placeholder="$00.00"
                  value={priceSmall}
                  style={{ borderColor: isDataMissing && !isOnePriceSet && !priceSmall ? "red" : "#B0B0B0" }}
                  type="text"
                  onChange={handlePriceSmallChange}
                />
                <label style={{ color: "#868686", alignSelf: 'center' }}>Small</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: 10,
                  maxWidth: '30%',
                }}
              >
                <input
                  className={classes.input}
                  placeholder="$00.00"
                  value={priceMedium}
                  style={{ borderColor: isDataMissing && !isOnePriceSet && !priceMedium ? "red" : "#B0B0B0" }}
                  type="text"
                  onChange={handlePriceMediumChange}
                />
                <label style={{ color: "#868686", alignSelf: 'center' }}>Medium</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: 10,
                  maxWidth: '30%',
                }}
              >
                <input
                  className={classes.input}
                  placeholder="$00.00"
                  value={priceLarge}
                  style={{ borderColor: isDataMissing && !isOnePriceSet && !priceLarge ? "red" : "#B0B0B0" }}
                  type="text"
                  onChange={handlePriceLargeChange}
                />
                <label style={{ color: "#868686", alignSelf: 'center' }}>Large</label>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginLeft: 10,
              maxWidth: '45%',

            }}
          >
            <label style={{ color: "#F56200" }} className={classes.label}>Stock</label>
            <input
              className={classes.input}
              placeholder="Stock"
              value={stock}
              type="text"
              onChange={handleStockChange}
            />
          </div>
        </div>
        <div style={{ marginTop: '10px' }}>
          <label className={classes.label}>Image</label>
          <div className={classes.imageUploadContainer}>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className={classes.hiddenFileInput}
              onChange={handleImageChange}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            {!imageSrc && (
              <>
                <div className={classes.uploadIcon}>
                  <UploadIcon />
                </div>
                <div>
                  <span style={{ color: '#F56200', textDecoration: 'underline' }}>Click</span> to add your image
                </div>
              </>
            )}
            {imageSrc && (
              <img
                src={imageSrc}
                alt="Product"
                className={classes.image}
                style={{ position: 'absolute', top: '0' }}
              />
            )}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <label className={classes.label}>Product tags</label>
              <IconButton  
                children={isEditing[0]?
                  <CheckCircleIcon sx={{color:"#008A25"}} /> : 
                  <EditIcon />}
                onClick={() => handleButtonClick(0)}
              >
              </IconButton>
            </div>
            <div style={{ display: 'flex' }}>
              <input
                className={classes.inputButton}
                style={{ borderColor: isDataMissing && !name ? "red" : "B0B0B0" }}
                placeholder="Add tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
              <Button
                variant="contained"
                disableElevation
                endIcon={<AddIcon/>}
                style={{  
                  borderRadius: "0 8px 8px 0",
                  padding: '5px 10px',
                }}
                disabled={loadingEditor}
                onClick={() => {
                  addTag(tag);
                }}
              >Add</Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: '10px' }}>
            {tags&&(Object.entries(tags).map(([tag, isSelected], index) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', marginRight:'10px' }}>
                <label style={{ fontSize: '18px', width: "100%" }}>
                  {!isEditing[0]&&(
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleTagChange(tag)}
                    /> 
                  )}
                  {tag}
                  {isEditing[0] ? (
                    <IconButton sx={{padding:'0px'}} small  children={<ClearIcon />} onClick={() => handleTagDelete(tag)} />
                  ): null}
                </label>
                
              </div>
            )))}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <label className={classes.label}>Customization</label>
              <IconButton  
                children={isEditing[1]?
                  <CheckCircleIcon sx={{color:"#008A25"}} /> : 
                  <EditIcon />} 
                  onClick={() => handleButtonClick(1)}
              >
              </IconButton>
            </div>
            <div style={{ display: 'flex' }}>
              <input
                className={classes.inputButton}
                style={{ borderColor: isDataMissing && !name ? "red" : "B0B0B0" }}
                placeholder="Add customizable product"
                value={custom}
                onChange={(e) => setCustom(e.target.value)}
              />
              <Button
                variant="contained"
                disableElevation
                endIcon={<AddIcon/>}
                style={{  
                  borderRadius: "0 8px 8px 0",
                  padding: '5px 10px',
                }}
                disabled={loadingEditor}
                onClick={() => {
                  addCustom(custom);
                }}
              >Add</Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: '10px' }}>
            {customs&&(Object.entries(customs).map(([custom, isSelected], index) => (
              <div 
                key={index} 
                style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  marginTop: '5px', 
                  marginRight:'10px'
                }}
              >
                <label style={{ fontSize: '18px', width: "100%" }}>
                  {!isEditing[1]&&(
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => handleCustomChange(custom)}
                    /> 
                  )}
                  {custom}
                  {isEditing[1]?(
                    <IconButton sx={{padding:'0px'}} small  children={<ClearIcon />} onClick={() => handleCustomDelete(custom)} />
                  ): null}
                </label>
                
              </div>
            )))}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <label className={classes.label}>Extras</label>
              <IconButton  
                children={isEditing[2] ?
                  <CheckCircleIcon sx={{color:"#008A25"}} /> : 
                  <EditIcon />} 
                onClick={() => handleButtonClick(2)}
              >
              </IconButton>
            </div>
            <div style={{ display: 'flex' }}>
              <input
                className={classes.inputButton}
                style={{ borderColor: isDataMissing && !name ? "red" : "B0B0B0" }}
                placeholder="Add extra product"
                value={extra}
                onChange={(e) => setExtra(e.target.value)}
              />
              <Button
                variant="contained"
                disableElevation
                endIcon={<AddIcon/>}
                style={{  
                  borderRadius: "0 8px 8px 0",
                  padding: '5px 10px',
                }}
                disabled={loadingEditor}
                onClick={() => {
                  addExtras(extra);
                }}
              >Add</Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingTop: '10px' }}>
            {extras && (Object.entries(extras).map(([extra], index) => (
              <div 
                key={index} 
                style={{ 
                  display: 'flex', 
                  justifyContent: 'flex-start', 
                  marginTop: '5px', 
                  marginRight:'10px',
                  alignItems: 'baseline',
                }}
              >
                <label style={{ fontSize: '18px'}}>
                  {!isEditing[2] && (
                    <input
                      type="checkbox"
                      checked={extras[extra].isSelected}
                      onChange={() => handleExtrasChange(extra)}
                    /> 
                  )}
                  {extra}
                  
                  {isEditing[2] ? (
                    <IconButton sx={{padding:'0px'}} small  children={<ClearIcon />} onClick={() => handleExtrasDelete(extra)} />
                  ) : null}
                </label>
                <input
                    className={classes.input}
                    placeholder="$00.00"
                    value={extras[extra].price}
                    style={{ marginLeft: '10px', width: '60px' }}
                    type="text"
                    onChange={(e) => handleExtrasPriceChange(extra, e.target.value)}
                  />
              </div>
            )))}
          </div>
        </div>
        <Button
          variant="contained"
          style={{
            alignSelf: "center",
            justifySelf: "center",
            width: "90%",
            marginTop: "50px",
            borderRadius: "8px",
          }}
          disabled={loadingEditor}
          onClick={() => {
            setLoadingEditor(true);
            validate();
          }}
        >
          Save
        </Button>

        <Button
          style={{
            backgroundColor: 'transparent',
            color: '#F56200',
            border: '1px solid #F56200',
            borderRadius: '8px',
            width: '90%',
            alignSelf: 'center',
            justifySelf: 'center',
            marginTop: '10px',
          }}
          onClick={() => {
            closeAction();
            resetFields();
          }}
        >
          Cancel
        </Button>

      </div>
    </Drawer>
  );
}

export default ProductEditor;
