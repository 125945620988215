export const pieData = {
  labels: ["Red", "Blue"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19],
      backgroundColor: ["#94D13D", "#2DDAC1"],
    },
  ],
};

export const barData = {
  labels: ["1", "2", "3", "4", "5", "6", "7"],
  datasets: [
    {
      axis: "y",
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: "#6F58E9",
    },
  ],
};
export const tableData = [
  { name: "McDonalds", total: 45387.43 },
  { name: "Subway", total: 298781.94 },
  { name: "Taco Bell", total: 155890.17 },
  { name: "Dunkin Donuts", total: 96841.43 },
  { name: "KFC", total: 74132.78 },
];
