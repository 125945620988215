import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { getAllTenants, addTenant, updateTenant } from "../../axios/tenants";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import InfoIcon from "@mui/icons-material/Info";
import { ListContainer } from "../../components/listContainer";
const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    marginLeft: "2%",
    marginRight: "2%",
  },
  containerTitle: {
    fontSize: "40px",
    lineHeight: "50px",
    textAlign: "left",
    height: "10px",
  },
  containerSubtitle: {
    fontSize: "20px",
    lineHeight: "25px",
    textAlign: "left",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  addButton: {
    background: "#F56200",
    height: "50px",
    width: "170px",
    border: "2px solid #F56200",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "18px",
    lineHeight: "23px",
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "5px",
    marginTop: 15,
  },
  input: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #B8B8B8",
    borderRadius: "8px",
    height: 25,
    padding: "8px 16px",
    fontSize: "130%",
    marginBottom: 10,
  },
  button: {
    alignSelf: "end",
    justifySelf: "end",
    width: "40%",
  },
});

function Tenants() {
  const [tenants, setTenants] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [selected, setSelected] = useState();
  const [loadingEditor, setLoadingEditor] = useState(false);
  const [editorName, setEditorName] = useState("");

  const history = useHistory();

  useEffect(async () => {
    const data = await getAllTenants();
    setTenants(data.result);
  }, []);

  async function saveTenant() {
    if (editorName) {
      setLoadingEditor(true);
      if (selected) {
        const sendOb = selected;
        sendOb.name = editorName;
        await updateTenant(sendOb);
      } else {
        await addTenant({ name: editorName });
      }
      setOpenEditor(false);
      setEditorName("");
      setSelected(false);
      setLoadingEditor(false);
      const data = await getAllTenants();
      setTenants(data.result);
    }
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Drawer
        anchor="right"
        open={openEditor}
        onClose={() => {
          if (loadingEditor) return;
          setOpenEditor(false);
          setEditorName("");
          setSelected(false);
          setLoadingEditor(false);
        }}
        PaperProps={{
          style: {
            width: "40%",
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
          },
        }}
      >
        <h1>{selected ? "Edit Tenant" : "New Tenant"}</h1>
        <div className={classes.form}>
          <label className={classes.label}>Name</label>
          <input
            className={classes.input}
            placeholder="Name of business"
            value={editorName}
            onChange={(e) => setEditorName(e.target.value)}
          />
          <Button
            className={classes.button}
            variant="contained"
            style={{ marginTop: 50 }}
            onClick={saveTenant}
            disabled={loadingEditor}
          >
            Save
          </Button>
        </div>
      </Drawer>
      <div className={classes.headerContainer}>
        <div>
          <h1 className={classes.containerTitle}>Tenants</h1>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => setOpenEditor(true)}
          >
            Add new tenant
          </Button>
        </div>
      </div>
      <ListContainer
        columns={[
          { name: "Name Business", key: "name" },
          {
            name: "Num. Branches",
            key: "branches",
            length: true,
            center: true,
          },
          {
            actions: [
              {
                icon: <ModeEditIcon />,
                click: (ele) => {
                  setOpenEditor(true);
                  setSelected(ele);
                  setEditorName(ele.name);
                },
              },
              {
                icon: <InfoIcon />,
                click: (ele) => {
                  history.push({ pathname: "/branches/" + ele.id });
                },
              },
            ],
            center: true,
          },
        ]}
        list={tenants}
      />
    </div>
  );
}

export default Tenants;
