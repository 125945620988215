import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import Check from '../../assets/boards/check.svg';
import { formatPrice, getColorForElapsedTime } from '../../utils/helperFunctions';
import { ORDER_STATUS, PAYMENT_STATUS } from '../../utils/orders';
import { processAndCapturePayment, checkPaymentStatus } from '../../axios/stripe';
import CircularProgress from '@mui/material/CircularProgress';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const useStyles = makeStyles({
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backdropFilter: 'blur(5px)',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        border: '1px solid #ccc',
        borderRadius: '15px',
        width: '35%',
        height: '85%',
        backgroundColor: 'white',
        overflowY: 'auto',
        position: 'relative',
        padding: '0 10px 10px 10px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    },
    order: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        marginBottom: '10px',
        height: '100%'

    },
    orderHeader: {
        display: 'flex',
        flexDirection: 'row',
        height: '15%',
        color: '#fff',
        borderRadius: '10px 10px 0 0',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
    },
    orderNumber: {
        fontWeight: 'bold',
        fontSize: '24px',
        
    },
    orderBreakDown: {
        backgroundColor: 'white',
        minHeight: '95%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '0 0 10px 10px',
    },
    categoryHeader: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#D9D9D9',
        fontSize: '16px',
        color: '#868686',
        fontWeight: 'bold',
        padding: '10px',
        marginBottom: '10px',
    },
    productRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: '4% 4% 4% 6%',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
    },
    productCount: {
        border: 'solid 2px #F56200',
        padding: '1%',
        borderRadius: '4px',
        color: '#F56200',
        fontWeight: 'bolder',
    },
    productCountCheck: {
        border: 'solid 2px #008A25',
        padding: '1%',
        borderRadius: '4px',
        color: '#FFFFFF',
        backgroundColor: '#008A25',
        fontWeight: 'bolder',
    },
    productDetails: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '5%',
        flexDirection: 'column',
        flexGrow: 1,
    },
    productName: {
        fontWeight: 'bolder',
    },
    productNameCheck: {
        fontWeight: 'bolder',
        color: '#008A25',
    },
    productSize: {
        color: '#00000099',
    },
    productPrice: {
        fontWeight: 'bolder',
        marginLeft: '20px',
    },
    productCheck: {
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
    },
    totalInfo: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        marginTop: '10px',
        justifyContent: 'flex-end',

    },
    totalLabels: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    totalItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
    },
    totalLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        paddingRight: '10px',
    },
    totalValue: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    taxesLabel: {
        paddingTop: '3px',
        paddingBottom: '3px',
        fontSize: '12px',
        color: 'gray',
    },
    totalAmount: {
        color: '#F56200',
    },
    paymentMethod: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    paymentOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100px',
        height: '50px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        margin: '0 10px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'gray',
    },
    deliverButton: {
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '5%',
        width: '45%'
    },
    paymentSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
    },
});
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
const OrderDetailsModal = ({ isOpen, onClose, order, setOrderForUpdate, handleOrderUpdate }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState('');
    const [isOrderReady, setIsOrderReady] = useState(false);
    const [isOrderPaid, setIsOrderPaid] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [loadingCash, setLoadingCash] = useState(false);

    useEffect(() => {
        if (order) {
            setIsOrderReady(order.status === ORDER_STATUS.READY);
            setIsOrderPaid(order.paymentStatus === PAYMENT_STATUS.PAID);
        }
    }, [order]);

    if (!isOpen) return null;

    const calculatePretotal = () => {
        return Object.values(order.orderList).reduce((total, category) => {
            return total + category.Products.reduce((subtotal, product) => {
                return subtotal + product.price * product.count;
            }, 0);
        }, 0);
    };

    const pretotal = calculatePretotal();
    const taxes = 0;
    const total = pretotal + taxes;

    const POLLING_INTERVAL = 5000; // 5 segundos

    const handleCardPayment = async () => {
        if (loadingCard || order.paymentStatus == PAYMENT_STATUS.PAID) return;
        setLoadingCard(true);
        try {
            const response = await processAndCapturePayment(total, 'usd');
            if (response.paymentIntentId) {
                await pollPaymentStatus(response.paymentIntentId);
            } else if (response.error) {
                setErrorMessage(response.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred during the payment process.');
        } finally {
            setLoadingCard(false);
        }
    };

    const pollPaymentStatus = async (paymentIntentId) => {
        let status = 'requires_payment_method';
        while (status === 'requires_payment_method' || status === 'requires_action') {
            await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
            try {
                const response = await checkPaymentStatus(paymentIntentId);
                status = response.status;
                if (status === 'succeeded') {
                    setOrderForUpdate(order.id, 'payment');
                    setIsOrderPaid(true);
                    setErrorMessage('');
                    break;
                } else if (status === 'requires_payment_method') {
                    setErrorMessage('Payment failed, requires new payment method');
                    break;
                }
            } catch (error) {
                console.error('Error:', error);
                setErrorMessage('An error occurred while checking the payment status.');
                break;
            }
        }
    };

    const handleCashPayment = async () => {
        if (loadingCash || order.paymentStatus == PAYMENT_STATUS.PAID) return;
        setLoadingCash(true);
        try {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setOrderForUpdate(order.id, 'payment');
            setIsOrderPaid(true);
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred during the payment process.');
        } finally {
            setLoadingCash(false);
        }
    };

    const getOrderStatus = () => {
        if (!isOrderReady) return 'Some Items Pending';
        if (!isOrderPaid) return 'Awaiting Payment';
        return 'Deliver';
    }

    return (
        <div className={classes.modalOverlay} onClick={onClose}>
            <div className={classes.modalContent} style={{ backgroundColor: '#F56200' }} onClick={(e) => e.stopPropagation()}>
                <CloseIcon className={classes.closeButton} onClick={onClose} />
                {order && (
                    <div className={classes.order}>
                        <div className={classes.orderHeader}>
                            <div className={classes.orderNumber}>#00{order.orderNumber}</div>
                        </div>
                        <div className={classes.orderBreakDown}>
                            {Object.entries(order.orderList).map(([category, categoryData]) => (
                                <div key={category}>
                                    <div className={classes.categoryHeader}>-{category}-</div>
                                    {categoryData.Products.map((product, productIndex) => (
                                        <div key={`${productIndex}-${category}-${order.id}`} className={classes.productRow}>
                                            {product.status !== ORDER_STATUS.READY 
                                            ? <div className={classes.productCount}>{product.count}</div>
                                            : <div className={classes.productCountCheck}>{product.count}</div>
                                            }
                                            
                                            <div className={classes.productDetails}>
                                                {product.status !== ORDER_STATUS.READY 
                                                ? <div className={classes.productName}>{product.name}</div>
                                                : <div className={classes.productNameCheck}>{product.name}</div>
                                                }
                                                <div className={classes.productSize}>{capitalizeFirstLetter(product.size)}</div>
                                            </div>
                                            <div className={classes.productCheck}>
                                                {product.status === ORDER_STATUS.READY && <img src={Check} alt="Check" />}
                                            </div>
                                            <div className={classes.productPrice}>{formatPrice(product.price)}</div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className={classes.bottomSection}>
                                <div className={classes.totalInfo}>
                                    <div className={classes.totalLabels}>
                                        <span className={classes.totalLabel}>Pretotal:</span>
                                        <span className={`${classes.totalLabel} ${classes.taxesLabel}`}>Taxes:</span>
                                        <span className={`${classes.totalLabel} ${classes.totalAmount}`}>Total:</span>
                                    </div>
                                    <div className={classes.totalLabels}>
                                        <span className={classes.totalValue}>{formatPrice(pretotal)}</span>
                                        <span className={`${classes.totalValue} ${classes.taxesLabel}`}>{formatPrice(taxes)}</span>
                                        <span className={`${classes.totalValue} ${classes.totalAmount}`}>{formatPrice(total)}</span>
                                    </div>
                                </div>
                                <div className={classes.paymentSection}>
                                    <div style={{
                                        color: '#868686',
                                        marginBottom: '10px' 
                                    }}>Choose payment method</div>
                                    <div className={classes.paymentMethod}>
                                        <div
                                            className={classes.paymentOption}
                                            onClick={() => handleCardPayment()}
                                            disabled={loadingCard}
                                            style={{ cursor: loadingCard ? 'not-allowed' : 'pointer' }}
                                        >
                                            <CreditCardIcon />
                                            {loadingCard ? <CircularProgress size={24} /> : 'Card'}
                                        </div>
                                        <div
                                            className={classes.paymentOption}
                                            onClick={() => handleCashPayment()}
                                            disabled={loadingCash}
                                            style={{ cursor: loadingCash ? 'not-allowed' : 'pointer', display: 'flex', alignItems: 'center' }}
                                        >
                                        <LocalAtmIcon />
                                        {loadingCash ? <CircularProgress size={24} /> : 'Cash'}
                                        </div>
                                    </div>
                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    <button
                                        className={classes.deliverButton}
                                        onClick={() => handleOrderUpdate(order.id)}
                                        disabled={!isOrderReady || !isOrderPaid || loadingCard || loadingCash}
                                        style={{ backgroundColor: (isOrderReady && isOrderPaid) ? 'green' : 'gray' }}
                                    >
                                        {getOrderStatus()}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderDetailsModal;