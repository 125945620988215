import axios from "axios";

const URL = process.env.REACT_APP_ROOT_API;

export function processAndCapturePayment(amount, currency) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${URL}/process_and_capture_payment`, { amount, currency })
      .then((response) => {
        const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkPaymentStatus(paymentIntentId) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${URL}/check_payment_status`, {
        params: {
          payment_intent_id: paymentIntentId,
        },
      })
      .then((response) => {
        const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        resolve(data);      
      })
      .catch((error) => {
        reject(error);
      });
  });
}
