import React from "react";
import { RotatingLines } from "react-loader-spinner";

function Modal() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <dialog open>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          width="100"
          visible={true}
        />
      </dialog>
    </div>
  );
}

export default Modal;
