import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "16px",
    border: "1px solid #B8B8B8",
    overflow: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
    minHeight: 65,
    backgroundColor: "#F56200",
    paddingRight: 20,
    paddingLeft: 20,
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: 30,
  },
});

export const GraphContainer = (props) => {
  const { children, title, subtitle } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div
        className={classes.topContainer}
        style={{ justifyContent: subtitle ? "space-between" : "center" }}
      >
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
      <div className={classes.bottomContainer}>{children}</div>
    </div>
  );
};

export default GraphContainer;
